import React from 'react';
import Layout from '@layouts';
import { SEO, TAIKScholars } from '@components';

const Index = () => (
  <Layout bannerStyle={{ backgroundImage: 'none' }} hide>
    <SEO
      title='TAIK SCHOLARS PROGRAM'
      description='The TAIK Scholars Program empowers high schoolers in STEM Fields'
    />
    <TAIKScholars />
  </Layout>
);

export default Index;
